@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;


* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: #ffffff;
  margin: 0;
  font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
html {
  overflow-x: hidden !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

section {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
  background: #f7f9fc !important;
}

@media (min-width: 767px) {
  section:not(.shop-section-padding-top) {
    /*min-height: 80vh !important;*/
    min-height: 600px !important;
  }
}

a {
  text-decoration: none;
  color: black;
}

li {
  list-style: none;
}

input,
button {
  border: none;
  outline: none;
  background-color: none;
}

.heading {
  display: flex;
  align-items: center;
  gap: 10px;
}

.heading h1 {
  width: fit-content;
  text-align: center;
  margin: 20px auto 30px !important;
}

.images-container {
  display: flex;
}

/*   ---Home Slider---   */

.homeSlide {
  display: flex;
  justify-content: center;
  background-color: #f6f9fc;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

/*   ---Shop Pages---   */

.image-container {
  position: relative;
  width: 100%;
  height: 50px;
  overflow: hidden;
}

.filter-bar-contianer .row {
  flex-wrap: wrap-reverse !important;
}

.filter-bar {
 padding-top: 0 !important;
}

@media (max-width: 767px) {
  .filter-bar .row {
    gap: 20px;
  }
}

.not-found {
  margin: 40px auto 20px;
  width: fit-content;
}

/*   ---Cart Page---   */

.cart-items {
  background: white !important;
  height: auto;
}

.cart-items .cart-total {
  background-color: white;
  padding: 20px;
  margin-bottom: 30px;
  position: relative;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 10%);
}

.cart-list {
  position: relative;
  background: white;
  margin-bottom: 30px;
  padding: 0;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 10%);
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  width: 100%;

}

.cart-list .image-holder {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cart-items img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  max-width: 140px;
  min-height: 150px;
}

.productNotFoundSection {
  min-height: 70vh;
}

.productNotFoundSection button {
  width: 100%;
  max-width: 400px !important;
}

.website-logo-cart {
  width: 165px !important;
  height: 100% !important;
  margin: 15px 0 !important;
  object-fit: fill !important;
  max-height: none !important;
  max-width: none !important;
}

.gacd_logo_cart {
  width: 130px !important;
}

.platform-group {
  position: relative;
}

.productsListTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.productsListTop #items-per-page {
  min-width: 15rem;
  text-align: center;
  padding-left: 0 !important;
  font-size: 14px;
  font-weight: 500;
}


.product-list-items {
  display: grid !important;
  grid-template-columns: repeat(3, 1fr);

}

@media (max-width: 1026px) {
  .product-list-items {
    grid-template-columns: repeat(2, 1fr) !important;
    max-width: 100% !important;
  }
}


.resetFormBtn {
  color: #d2d2d2;
  font-size: 32px;
  cursor: pointer;
}

@media (max-width: 767px) {

  .product-list-items {
    grid-template-columns: repeat(1, 1fr) !important;
  }

  .result-count {
    margin: 0 !important;
  }

  .productsListTop label {
    font-size: 14px;
  }

  .productsListTop {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 0rem;
  }

  .result-count-select {
    display: flex;
    flex-direction: column;
  }

  .productsListTop #items-per-page{
    padding-left: 10px !important;
  }

  .profilSection {
    padding: 2rem 1rem !important;
  }

  section {
    padding-top: 20px !important;
    padding-bottom: 30px !important;
  }

  .cart-list {
    justify-content: center !important;
  }

  .cart-list h3 {
    font-size: 16px !important;
  }

  .cartControl {
    flex-direction: row-reverse !important;
    margin: 1rem !important;
  }

  .cart-items .container > .row {
    flex-wrap: wrap !important;
  }

  .cart-items .col-md-12 {
    padding: 0 !important;
  }

  .noResultCartText h1 {
    font-size: 16px !important;
  }

}

@media (max-width: 567px) {
  .cart-items .col-md-12 {
    padding: 1rem !important;
  }
}


.cart-items .cart-content {
  align-items: flex-end;
}

.cart-items .cart-details h3 {
  font-size: 20px;
  font-weight: 500;
  width: fit-content;
  text-align: center;
  margin-top: 20px;
}

.cart-items .cart-details h4 {
  font-size: 15px;
  font-weight: 400;
  margin-top: 20px;
  color: grey;
  width: fit-content;
}

.cart-items h4 span {
  color: #7eb3ff;
  margin-left: 20px;
  font-weight: 500;
}

.removeCart {
  background: none;
  font-size: 25px;
}

.cartControl {
  display: flex;
  gap: 5px;
  justify-content: center;
  padding: 0 !important;
  margin: 20px 0 0;
}

.cartControl button {
  width: 35px;
  height: 35px;
  border-radius: 5px;
  font-size: 18px;
}

.incCart {
  background: #7eb3ff;
  border: 1px solid rgb(3 0 71 / 9%);
  color: white;
}

.incCart:hover {
  background: #0076ff8f;
}

.desCart {
  background: #f2f2f2 !important;
}

.desCart:hover {
  background: #e6e6e6 !important;
}

.cart-total h4 {
  font-size: 15px;
  font-weight: 400;
}

.cart-total h3 {
  font-size: 20px;
  font-weight: 500;
  color: #7eb3ff;
}

.cart-total h2 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
  border-bottom: 1px solid rgb(3 0 71 / 9%);
  padding-bottom: 10px;
  color: #1a1a19;
}

.no-items {
  color: #1a1a19;
  font-size: 16px;
  margin-top: 30px;
  height: 3rem;
}

button.delete {
  position: absolute;
  right: 0;
  top: 0;
  width: max-content;
  border: none;
  outline: none;
  background-color: #524c4c;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: .05rem;
  border-radius: 0 0 0 .51rem;
}

button.delete:hover {
  background-color: #363333;
}

button.delete ion-icon {
  font-size: 24px;
}

@media (max-width: 575px) {
  .cart-content .cart-details h3 {
    margin: 10px auto;
  }

  .cart-content .cart-details h4 {
    margin: 10px auto;
  }
}

.slick-prev:before, .slick-next:before {
  font-family: 'slick', serif;
  font-size: 25px !important;
  line-height: 1;
  opacity: .75;
  color: #242222 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -45px !important;
}

.slick-next {
  right: -45px !important;
}

.pagination-controls {
  display: flex;
  align-items: center;
}

.pagination-controls button {
  margin: 0 10px;
}

.no-underline {
  text-decoration: none !important;
}

#popup-modal {
  z-index: 99999;
}

.cartAdminListItem {
  position: relative;
}

.cartAdminListItem img {
    width: 100%;
    height: 100%;
    max-width: 250px;
    object-fit: contain;
}



.register-padding-top {
  padding-top: 6rem !important;
}



#popup-modal > div:first-child {
  min-width: 750px;
}


#popup-modal > div:first-child > div:first-child {
  padding: 1rem;
}

[type='checkbox']:focus {
  box-shadow: none !important;
}

.formSearchTitle {
    font-size: .9rem;
}

.modalBtnsDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem !important;
}



.loginDivCard {
  min-width: 600px;
}

.forgotPasswordSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.signupDivCard {
    max-width: 80vw !important;
    min-width: 900px !important;
}


.signupFormGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.signupBtn {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    gap: .5rem;
}

.signupBtn button {
  height: 42px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: .51rem;
}

.loginDivCard .loginBtn {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  gap: .5rem;
}

.gainDiscount {
  position: absolute;
  bottom: 20px;
  left: 0;
  background-color: #ff6c68;
  padding: .5rem .7rem;
  color: white;
  font-size: 10px;
  border-radius: 0 1rem 1rem 0;
}

.totalDiscount {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: #7eb3ff;
    padding: .5rem .7rem;
    color: white;
    font-size: 12px;
}



.cartItemInfo {
    display: flex;
    justify-content: center;
    align-items: center;
}

.cartItemInfo .cart-content {
  width: 100%;
}

.nav-link {
  font-size: 16px !important;
}

.totalDiscount h3 {
  color: white !important;
  font-size: 12px !important;
  margin: 0 !important;
  padding: 0 !important;
}

.navbar-nav small {
    font-size: 14px !important;
  font-weight: 400;
}

.clientsCartsDiv {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  margin: 0 !important;
  padding: 0 !important;
}


.cartItemsAdminPage {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem;
  margin: 0 !important;
  padding: 0 !important;
}


.productCardDetailsModal > div:first-child  {
  min-width: 95vw !important;

}

.productCardDetailsModalContent {
  padding: 0 !important;

}

.productCardDetailsModalContentProductDetails {
  height: 100% !important;
  overflow-x: hidden;
  max-height: 80vh;
  padding: 0 !important;
  margin: 0 !important;
  overflow-y: scroll;
}

.productCardDetailsModalContentProductDetails .product-page-details {
  width: 100%;
  overflow: hidden;
}

.productCardDetailsModalContent .quantityInput {
  text-align: center !important;
}

.productCardDetailsModalContent .product-page {
    padding: 0 !important;
    margin: 0 !important;
}

.Toastify__toast-container {
    z-index: 9999999 !important;
}
.Toastify__toast-container--top-right {
  top: 0 !important;
  right: 0 !important;
  margin-bottom: 1rem !important;
}

.addToCartDiv {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: .5rem;
}

.addToCartDivContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  gap: 1rem !important;
}

.loadingProductListSpinner {
  align-items: center;
  min-height: 30rem !important;
}

.suggestionsDiv {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background-color: white;
  padding: 2rem;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 10%);
  min-height: 60vh;
  border-radius: 8px;
}

.suggestionsDiv p:first-child {
  padding: 2rem 3rem !important;
  background-color: #f8f9fc;
  border-radius: 8px;
  box-shadow: 0 0 3px 0 rgb(24 122 235 / 27%);
}

.suggestionsCards {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(1, 1fr);
  min-width: calc(100% - 2rem);
  min-height: calc(100% - 2rem);

}

@media (min-width: 768px) {
  .suggestionsCards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1024px) {
  .suggestionsCards {
    grid-template-columns: repeat(4, 1fr);
  }
}

.suggestionsCards .suggestionsCard {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    border: 1px solid #f2f2f2 !important;
    border-radius: 5px;
    width: 100%;
  box-shadow: 0 0 3px 0 rgb(24 122 235 / 27%);
  justify-content: flex-start;
  text-align: left;
  position: relative;
  background-color: #f8f9fc !important;

}

.suggestionsCards .suggestionsCard:hover {
  border: 1px solid #f2f2f2 !important;
  box-shadow: 0 0 3px 0 rgb(24 122 235 / 47%);
  cursor: pointer;
}

.suggestionsCards .suggestionsCard .searchIcon {
  font-size: 15px;
  position: absolute;
  right: 10px;
  bottom: 12px;
  font-weight: bold;
  color: #494242;
}

.suggestionsCards .suggestionsCard h5 {
  font-size: large;
}

.suggestionsCards .suggestionsCard p,
.suggestionsCards .suggestionsCard strong {
  font-size: smaller;
  padding: 0 !important;
  margin: 0 !important;
}

.suggestionsCards .suggestionsCard strong{
  font-weight: bold;
  color: black;
}


@media (min-width: 767px) {
  .cart-link:hover svg {
    color: #0076ff8f;
  }
  .cart-link div {
    top: -15px !important;
    right: -15px !important;
  }
}

.noResultCartText h1 {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
    gap: .51rem !important;
}


.searchForm {
    background-color: white;
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 5%);
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border: 1px solid #f2f2f2;
  border-radius: 10px;
}

.searchFormWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.related-products h3 {
  padding-bottom: 2rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 7px;
}

#sort-options {
  font-size: .9rem !important;
  box-shadow: 0 0 10px 0 rgb(243 243 243 / 10%) !important;
  border-radius: 3px !important;
  border: 1px solid #e3e3e3 !important;
  padding-right: 2rem !important;
}

@media (max-width: 767px) {

  .related-products h3 {
    font-size: 18px !important;
    padding: 1rem;
    padding-top: 2rem;
    font-weight: bold;
  }


  .pertinentElementBadge {
    font-size: 12px !important;
    padding: 3px 15px !important;
  }

  .resetFormBtn {
    font-size: 20px;
  }



  .suggestionsDiv p:first-child {
    padding: 1rem !important;
    box-shadow: none !important;
  }

  .suggestionsCards .suggestionsCard {
    box-shadow: none !important;
  }

  .dividerSuggestions {
    display: none !important;
  }

  .Toastify__toast-container--top-right {
    top: 0 !important;
    right: 0 !important;
    margin-bottom: 1rem !important;
  }

  .productCardDetailsModal > div:first-child  {
    min-width: 98vw !important;
    padding: 0 !important;
  }


  .productCardDetailsModalContentProductDetails {
    max-height: 80vh !important;
    overflow-x: scroll;
  }

  .addToCartDivContent {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }

  .description-div {
    display: flex;
    flex-direction: column;
    gap: 2rem !important;
  }

  .descriptions {
    background-color: #f4f4f4;
    padding: 5rem 1rem !important;
  }

  .description-card {
    min-width: 100% !important;
    max-width: 100% !important;
    padding: 2rem 3rem !important;
  }

  .description-card h4 {
    font-size: 20px !important;
  }


  .description-card h6 {
    font-size: 16px !important;
  }

  .description-card p {
    font-size: 14px !important;
  }

  .description-card .description-card-details-element {
    min-width: 0 !important;
  }

    .addToCartDiv {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    max-width: max-content;
  }

  .cartItemsAdminPage {
    display: flex !important;
    flex-direction: column !important;

  }

  .clientsCartsDiv li {
    width: 100%;
  }

  .clientsCartsPage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }


  .clientsCartsDiv h2 {
    font-size: 16px !important;
  }

  .clientsCartsDiv p,
  .clientsCartsDiv span,
  .clientsCartsDiv strong {
    font-size: 14px !important;
  }

  .clientsCartsDivTitle {
    font-size: 18px !important;
  }

  .signupFormGrid {
    display: flex !important;
    flex-direction: column !important;

  }

  .signupDivCard {
    max-width: 90vw !important;
    min-width: 90vw !important;
  }

  .loginDivCard {
    min-width: 90vw;

    margin: 1rem;
  }

  .loginDivCard h1 {
    font-size: 18px;
  }

  .loginDivCard input {
    max-height: 40px;
  }



  .forgotPasswordSection {
    flex-direction: column !important;
    gap: 1rem;
    align-items: flex-start;
    font-size: 12px !important;
  }

  .loginDivCard .loginBtn {
    max-height: 35px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .loginDivCard p {
    font-size: 12px;
  }


  .shop-section-padding-top,
  .register-padding-top {
    padding-top: 0 !important;
  }

  .filter-bar form {
    padding: 0 15px;
  }


  .filter-bar form div label {
    font-size: 14px;
    margin-top: 1rem;
  }

  .filter-bar form div input {
    font-size: 14px;
  }

  .searchFromBtns {
    display: flex;
    flex-direction: row !important;
    gap: .1rem !important;
    justify-content: flex-end;
  }

  .content-container {
    padding: 0 15px;
  }

  .filter-bar form button {
    padding: 4px 16px !important;
    font-size: 14px;
  }

  #popup-modal > div:first-child {
    min-width: 0!important;
    max-width: 95vw !important;
  }

  #popup-modal button:not(.closeModalXbtn) {
    font-size: 14px !important;
    width: 100% !important;
    text-align: center !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 5px !important;
  }

  #popup-modal input {
    max-height: 35px !important;
    font-size: 14px !important;
  }

  #popup-modal p {
    font-size: 12px !important;
  }


  .modalBtnsDiv {
    flex-direction: column !important;
    gap: .1rem !important;
  }





  .cart-items img {
    max-width: 100px;
    object-fit: contain;
    min-height: 70px;
  }

  .loadingProductListSpinner {
    min-height: 10rem !important;
  }

}


.clientCartBtnPlus {
  max-height: 25px !important;
  background-color: #1a1a19 !important;
  color: white !important;
  max-width: 100% !important;
  font-size: .9rem;
  position: relative;
  width: 100%;
  margin-top: 1rem;
  display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  gap: 1rem !important;
}

.clientCartBtnPlus:hover,
.clientCartBtnPlus:active,
.clientCartBtnPlus:focus {
  background-color: #31312f !important;
  color: white !important;
  transform: scale(1.01);
}

.productSearchInput {
  font-size: 14px !important;
  border-radius: 5rem;
  position: relative;
}

.searchBtnSubmit {
  position: absolute !important;
  background-color: #f7f7f7 !important;
  color: #363232 !important;
  border-radius: 0 1rem 1rem 0;
  padding: 5px 15px !important;
  font-size: 28px !important;
  right: 0;
  top: 0;
  height: 100%;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #dde2e6;
}

.productSearchInput:focus,
.productSearchInput:active,
.productSearchInput:hover {
  outline: none !important;
  border: 1px solid #cbcbcb !important;
  --tw-ring-shadow: none !important;
  --tw-ring-color: transparent !important;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #dcdcdc !important;
  display: flex;
  justify-content: center;
  color: black;
  align-items: center;
}

.overlay .col {
  padding: 0 !important;
}