/* Navbar Section  */
.navbar {
  position: relative !important;
  background-color: white;
  transition: all 0.3s ease-out 0s !important;
  box-shadow: 0px 2px 5px 0px rgb(37 99 235 / 10%) !important;
  font-size: 1.2rem !important;
}

.textLogo {
  font-size: 30px;
}

.newNavbBarLinks a:not(.navbarBtnLink) {
  font-weight: bold !important;
  color: #000;
  font-size: 18px !important;
}

@media (min-width: 768px) {
  .newNavbBarLinks nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
  }
}




.navbar.fixed {
  position: fixed !important;
  width: 100vw;
  left: 0;
  top: 0;
}

.navbar-container {
  display: flex;
  justify-content: space-between !important;
}


.navbar-toggler:focus,
.navbar-toggler:active {
  outline: 0 !important;
  box-shadow: 0 0 0 transparent !important;
}


.navbar-brand {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.navbar-brand ion-icon {
  font-size: 25px;
}

.logo {
  font-size: 25px;
  font-weight: 500;
  color: black;
  margin: 0;
}

.navbar-link {
  display: flex !important;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-size: 10px !important;
  padding: 0.8rem 0.5rem 0.2rem !important;
}

.nav-link-label {
  color: black;
  font-size: 18px !important;
  font-weight: 600;
}

.nav-icon {
  width: 30px;
  height: 30px;
  padding-bottom: 5px;
}

.cart {
  position: relative !important;
  z-index: 3;
}

.cart::before {
  content: attr(data-num);
  position: absolute;
  right: 0;
  top: -5px;
  background-color: #2563eb;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  font-size: 11px;
  font-weight: 600;
  color: white;
  z-index: 5;
}


.navbar-nav .nav-item {
  position: relative;
  padding-bottom: 3px !important;
  margin: 0 1rem;
  font-weight: 400;
  transition: all 0.3s ease-out 0s;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 10px;
  z-index: 1;
}

.offcanvas.offcanvas-end {
  width: 300px !important;
}

.media-cart {
  display: none;
}

.email-link {
  cursor: default !important;
}

.logoutBtn {
  background-color: #0076ff8f;
}

.cartListIcon {
  background-color: #0076ff8f;

}

/* Responsive styles for tablets and smaller screens */
@media (max-width: 767px) {
  .navbar .container {
    padding: 0 1rem;
  }
  .navbar-collapse {
    margin-top: 1rem;
    border-top: 1px solid #28292a;
    padding-top: 1rem;
    position: relative;
  }
  .nav-link {
    font-size: 1rem !important;
    gap: 2rem;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
}

/* Additional responsive styles for smaller screens */
@media (max-width: 576px) {
  .navbar .container {
    padding: 0 1rem;
  }
  .navbar-collapse {
    margin-top: 1rem;
    border-top: 1px solid #28292a;
    padding-top: 1rem;
    position: relative;
  }
  .nav-link {
    font-size: 1rem !important;
    gap: 0;
    flex-direction: row;
    justify-content: flex-start;
  }
}

@media (max-width: 767px) {
  .cartListIcon {
    background-color: #7eb3ff;
    top: -12px !important;
    right: -20px;
    border: none !important;
  }
}


.logoutLink {
    cursor: pointer;
  color: #e73232 !important;
  font-weight: bold;
}


