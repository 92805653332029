footer {
  background-color: #212a38 !important;
  padding: 28px 0;
  color: white;
  padding-top: 3rem !important;
  box-shadow: 0 0 10px rgb(199 199 199 / 41%) !important;

}

footer .box {
  padding: 0 20px;
}

footer h1 {
  margin: 0;
  font-size: 25px;
  font-weight: 800;
  width: max-content;
}

footer .logo {
  color: white;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 20px;
}

footer h2 {
  font-size: 20px;
  margin-bottom: 20px;
}

footer ul {
  padding: 0;
}

@media (max-width: 768px) {
  footer .box:nth-child(2) {
    padding-top: 1rem !important;
  }

}

footer li {
  list-style: none;
  line-height: 2;
  margin-bottom: 10px;
}

footer a {
  color: white;
  font-size: 16px;
  text-decoration: underline;
  font-weight: bold;

}

footer a:hover {
  color: #eaeaea;
}

footer p {
  font-size: 16px;
  margin-bottom: 0px;
  font-weight: bold;
}

.text-center {
  text-align: center;
}

.text-muted {
  color: #6c757d !important;
}

.containerCookies {
  position: fixed;
    bottom: 10px;
    left: 10px;
  z-index: 9999;

}

.dark-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 999;
}

@media (max-width: 768px) {
  .containerCookies {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }


}